<table>
  <tr>
    <th>Number</th>
    <th>Release Date</th>
    <th>Release Notes</th>
    <th>URL</th>
    <th>API URL</th>
    <th>Deprecated</th>
  </tr>
  <tr *ngFor="let versionResult of versionResults">
    <td>{{ versionResult.VersionNumber }}</td>
    <td>{{ versionResult.ReleaseDate | date: 'MM/dd/yyyy' }}</td>
    <td>{{ versionResult.ReleaseNote }}</td>
    <td>{{ versionResult.SpaUrl }}</td>
    <td>{{ versionResult.ApiUrl }}</td>
    <td>{{ booleanFormatter(versionResult.Deprecated) }}</td>
  </tr>
</table>
