import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { AvatarComponent, ButtonComponent } from 'unity-angular';

@Component({
  selector: 'app-identity',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    AvatarComponent
  ],
  templateUrl: './identity.component.html',
  styleUrls: ['./identity.component.scss']
})
export class IdentityComponent {

  constructor(public authService: AuthService) { }

  public signIn(): void {
    this.authService.signIn();
  }

  public signOut(): void {
    this.authService.signOut();
  }

  public getAvatarInitials(): string {
    let initials = '';
    if (this.authService.user?.displayName && this.authService.user.displayName.length > 0) {
      const nameSegmentsInitials = this.authService.user.displayName.split(' ')
        .filter(s => s.length > 0)
        .map(s => s[0].toUpperCase());

      initials = nameSegmentsInitials[0];

      if (nameSegmentsInitials.length > 1) {
        initials += nameSegmentsInitials[nameSegmentsInitials.length - 1];
      }
    }

    return initials;
  }

}
