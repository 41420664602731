<header class="unity-header dark">
  <div class="unity-header-left">
    <div class="unity-header-item app-switcher">
      <button unity-button buttonStyle="icon-only" icon="menu" class="menu-button xlarge"
        (click)="onMenuButtonClicked()">
      </button>
      <svg class="unity-icon">
        <defs>
          <linearGradient id="insurity-i-gradient" x1="0" x2="0" y1="0" y2="1">
            <stop class="stop1" offset="0%" />
            <stop class="stop2" offset="100%" />
          </linearGradient>
        </defs>
        <g class="i">
          <path class="stem" d="M9.487 8.46h5.043v15.54h-5.043v-15.54z"></path>
          <path class="circle"
            d="M12.010 4.816c-0.897 0-1.626-0.73-1.626-1.626s0.729-1.626 1.626-1.626 1.626 0.73 1.626 1.626c0 0.897-0.729 1.626-1.626 1.626zM12.010 0c-1.762 0-3.19 1.428-3.19 3.19s1.428 3.19 3.19 3.19 3.19-1.428 3.19-3.19c0-1.761-1.428-3.19-3.19-3.19z">
          </path>
        </g>
      </svg>
    </div>
    <div class="unity-header-item logo">
      <h1 class="app-name">Admin Service</h1>
    </div>
    <div *ngIf="isGlobalAdminUser()">Global Admin</div>
  </div>
  <div class="unity-header-right">
    <div class="unity-header-item">
      <app-identity></app-identity>
    </div>
  </div>
</header>
