import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG } from '../app.config';
import { Area } from '../models/CodeGen/admin-model';

@Injectable({
  providedIn: 'root'
})
export class AreaService {

  constructor(@Inject(APP_CONFIG) private config: any, private httpClient: HttpClient) { }

  public list(tenantKey: string): Observable<Area[]> {
    return this.httpClient.get<Area[]>(`${this.config.ApiUrl}/Areas/${tenantKey}`);
  }

  public create(area: AreaPost) {
    return this.httpClient.post(`${this.config.ApiUrl}/Areas`, area);
  }
}

export interface AreaPost {
  tenantKey: string,
  areaKey: string,
  name: string,
  notes: string,
  alias: string,
  versionId: number
}
