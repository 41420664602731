import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import * as _ from 'lodash';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserContext } from 'src/app/contexts/user-context';
import {
  AdminLogin,
  Organization,
  Tenant,
} from 'src/app/models/CodeGen/admin-model';
import { ButtonModule } from 'unity-angular';
import { NavMenuComponent } from '../../nav-menu/nav-menu.component';
import { TenantsListComponent } from '../../tenant/tenant-list/tenant-list.component';

@Component({
  selector: 'organization-overview',
  standalone: true,
  imports: [
    NavMenuComponent,
    RouterOutlet,
    CommonModule,
    ButtonModule,
    TenantsListComponent,
  ],
  templateUrl: './organization-overview.component.html',
  styleUrls: ['./organization-overview.component.scss'],
})
export class OrganizationOverviewComponent implements OnInit, OnDestroy {
  public organizations: Organization[] = [];
  public allTenants: Tenant[] = [];
  public allUsers: any[] = [];
  public orgTenants: Tenant[] = [];
  public orgTenantsVMs: TenantByOrgVM[] = [];
  public orgUsers: AdminLogin[] = [];
  public orgUsersVMs: UserByOrgVM[] = [];
  public selectedOrg: Organization;
  public insurityOrg: Organization;
  public orgId: string;
  public currentUser: AdminLogin;
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private route: ActivatedRoute,
    private userContext: UserContext,
    private router: Router
  ) { }

  private routeSubcription: Subscription;

  ngOnInit() {
    var self = this;

    this.routeSubcription = this.route.params.subscribe((params) => {
      self.orgId = params['orgId'];
      //this.loadOrgAdminUsers();
      this.loadOrganizations();
      this.loadOrgTenants();
    });
  }

  ngOnDestroy(): void {
    if (this.routeSubcription) this.routeSubcription.unsubscribe();

    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }
  /*
      loadOrgAdminUsers(): void {
          let orgUsersSource = this.userContext.getStore(new User()).values;

          orgUsersSource.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
              this.allUsers = data;
              this.orgUsers = this.allUsers.filter(x => x instanceof AdminLogin);
              this.currentUser = this.orgUsers.find(x => x.AdminLoginId == this.authService.getIdentityKey());

              // filter to only this org's users or Super Users
              this.orgUsers = this.orgUsers.filter(u => {
                  if (u instanceof AdminUser && (u as AdminUser).Super) {
                      return true;
                  }
                  else if (u instanceof AdminUser) {
                      var edges = u.Represents();

                      if (!edges?.length) return false;

                      return edges[0].Represents() == this.selectedOrg;
                  }
              });


              this.orgUsersVMs = _.map(this.orgUsers, user => {
                  var userVM = {} as UserByOrgVM;
                  userVM.Email = user.Logon;
                  userVM.Super = user.Super;
                  userVM.IsActive = user.IsActive;

                  return userVM;
              });
          });
      }*/

  loadOrganizations(): void {
    let organizationSource = this.userContext.getStore(
      new Organization()
    ).values;

    organizationSource.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      this.organizations = data;

      if (this.orgId) {
        this.selectedOrg = this.organizations.find(
          (x) => x.Id == this.orgId
        ) as Organization;
        this.insurityOrg = this.organizations.find(
          (x) => x.Name == 'Insurity'
        ) as Organization;
      }
    });
  }

  loadOrgTenants(): void {
    let orgTenantsSource = this.userContext.getStore(new Tenant()).values;

    orgTenantsSource.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      this.allTenants = data;
      // filter to only this org's tenants
      this.orgTenants = this.allTenants.filter(
        (t) => 'Organization-' + t?.OrganizationId == this.orgId
      );
      this.orgTenantsVMs = _.map(this.orgTenants, (tenant) => {
        var tenantVM = {} as TenantByOrgVM;
        tenantVM.Key = tenant.TenantKey;
        tenantVM.Name = tenant.Name;

        return tenantVM;
      });
    });
  }

  isSuperUser() {
    return this.currentUser?.Super ? true : false;
  }
  /*
      onManageUsers() {
          this.router.navigate(['Organizations', this.selectedOrg.Id, 'Users' ]);
      }

      onManageTenants() {
          this.router.navigate(['Organizations', this.selectedOrg.Id]);
      }

      onViewTenantLog() {
          this.router.navigate(['Organizations', this.selectedOrg.Id, 'Logs' ]);
      }*/

  orgChanged(org: string) {
    if (this.selectedOrg.OrganizationKey == org) return;

    this.clearLists();
    var y = this.organizations.find((x) => x.OrganizationKey == org);
    this.selectedOrg = y as Organization;
    this.router.navigate(['Organizations', this.selectedOrg.Id]);
  }

  addOrganization() {
    this.router.navigate(['Organizations', this.selectedOrg.Id, 'Add']);
  }
  /*
   envHealth() {
       this.router.navigate(['Organizations', this.selectedOrg.Id, 'Env']);
   }*/

  onAddTenant() {
    this.router.navigate(['Organizations', this.orgId, 'AddTenant']);
  }

  onViewTenantLog() {
    this.router.navigate(['Organizations', this.orgId, 'Logs']);
  }

  clearLists() {
    this.orgUsersVMs = [];
    this.orgTenantsVMs = [];
  }
}

export interface UserByOrgVM {
  Email: string;
  Super: boolean;
  IsActive: boolean;
}

export interface TenantByOrgVM {
  Key: string;
  Name: string;
}
