import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationConfig, InjectionToken, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';
import { AuthClientConfig, AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { catchError, lastValueFrom, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { routes } from './app.routes';

let configuration: any;

export const APP_CONFIG = new InjectionToken<any>('appsettings.config');

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    {
      provide: APP_INITIALIZER,
      useFactory: appInitFactory,
      deps: [
        HttpBackend,
        AuthClientConfig
      ],
      multi: true
    },
    {
      provide: APP_CONFIG,
      useFactory: () => configuration
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi()),
    importProvidersFrom(AuthModule.forRoot())
  ]
};

function appInitFactory(httpBackend: HttpBackend, config: AuthClientConfig) {
  return () => new Promise(async (resolve, _) => {
    await loadConfiguration(httpBackend);

    configureAuth0(config)

    return resolve(true);
  });
}

async function loadConfiguration(httpBackend: HttpBackend) {
  let httpClient = new HttpClient(httpBackend);
  let appSettings: any = await lastValueFrom(httpClient.get(`assets/appsettings.json`).pipe(catchError(handleAppSettingsLoadError)));

  let env = environment.name;
  let envAppSettings: any = await lastValueFrom(httpClient.get(`assets/appsettings.${env}.json`).pipe(catchError(handleAppSettingsLoadError)));
  Object.assign(appSettings, envAppSettings);

  configuration = appSettings;

  function handleAppSettingsLoadError(error: any) {
    if (environment.name === 'Local')
      console.error(error);

    return of({});
  }
}

function configureAuth0(auth0Config: AuthClientConfig) {
  auth0Config.set({
    domain: configuration.Auth0.Domain,
    clientId: configuration.Auth0.ClientId,
    authorizationParams: {
      redirect_uri: window.location.href,
      audience: configuration.Auth0.Audience
    },
    httpInterceptor: {
      allowedList: [{
        uri: `${configuration.ApiUrl?.replace(/\/$/, '')}/*`,
        tokenOptions: {
          authorizationParams: {
            audience: configuration.Auth0.Audience
          }
        },
      }]
    }
  })
}
