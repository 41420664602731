import { DataContext } from "../contexts/data-context";


interface Serializable<T> {
    deserialize(input: { [key: string]: BigInt }, context: DataContext): T;
}

export abstract class Model implements Serializable<Model> {
    data: any = {};
    Context: DataContext;
    private _type: string;

    constructor(type: string) {
        this._type = type;
    }

    static GenerateId(type: string, id: any): string {
        if (typeof id == "string") {
            if (id.indexOf('-') > 0)
                return id;
        }
        return type + "-" + id;
    }

    public GenerateId(type: string, id: BigInt): string {
        return Model.GenerateId(this.Type, id);
    }

    get Type(): string {
        return this._type;
    }

    get Id(): string {
        return this.data.Id;
    }

    get Version(): string {
        return this.data.Version;
    }

    set Version(value: string) {
        this.data.Version = value;
    }

    deserialize(input: { [key: string]: BigInt }, context: DataContext): Model {
        if (!input) return this;
        this.data = input;
        this.data.Id = this.GenerateId(this.Type, input[this.Type + "Id"]);
        context.addOrReplace(this);
        this.Context = context;
        return this;
    }

}

export interface IServiceResponse {
    Content: any;
    Errors: IServerMessage[];
    Messages: IServerMessage[];
    RequestId: string;
}

export interface ITypedServiceResponse<T> extends IServiceResponse {
    Content: T;
}

export interface IServerMessage {
    Message: string;
    Data: object;
}

export interface IModelValidationError extends IServerMessage {
    Path: string;
}
