<table>
    <tr>
        <th>Key</th>
        <th>Name</th>
        <th>Notes</th>
    </tr>
    <tr *ngFor="let tenantModel of tenantVMs">
        <td><a href="javascript:void(0)" (click)="onEditTenant(tenantModel.tenant)">{{ tenantModel.tenant.TenantKey
                }}</a></td>
        <td>{{ tenantModel.tenant.Name }}</td>
        <td>{{ tenantModel.tenant.Notes }}</td>
    </tr>
</table>
