import { CommonModule } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";

//import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import _ from "lodash";
import { BehaviorSubject, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { UserContext } from "src/app/contexts/user-context";
import { Tenant } from "src/app/models/CodeGen/admin-model";

import { AdminService } from "src/app/services/admin-service";


@Component({
  selector: 'tenant-list',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './tenant-list.component.html',
  styleUrls: ['./tenant-list.component.scss']
})
export class TenantsListComponent implements OnInit, OnDestroy {

  @Input() orgId: string;

  public tenantSource: BehaviorSubject<Tenant[]>;
  public updatingDBs: Set<String> = new Set<String>();
  public tenantVMs: TenantViewModel[] = [];
  public srcTenants: Tenant[] = [];
  public now: number = (new Date()).getTime();
  private ngUnsubscribe: Subject<any> = new Subject();

  //@ViewChildren('enableSwitch') enableSwitchControls: QueryList<SwitchComponent>;

  constructor(private adminService: AdminService,
    private userContext: UserContext,
    private router: Router,
    //private modalService: NgbModal
  ) { }
  ngOnInit(): void {
    this.tenantSource = this.userContext.getStore(new Tenant()).values;

    this.tenantSource.pipe(takeUntil(this.ngUnsubscribe)).subscribe((data) => {
      if (!data?.length) return;
      this.srcTenants = <Tenant[]>data;
      this.loadTenants();
    });
  }

  /// Re-evaluate filter based on new org
  ngOnChanges() {
    this.loadTenants();
  }

  private loadTenants() {
    if (this.orgId) {
      let tenants = this.srcTenants.filter(t => "Organization-" + t?.OrganizationId === this.orgId);
      this.tenantVMs = this.mapTenantsToViewModels(tenants);
    } else {
      this.tenantVMs = this.mapTenantsToViewModels(this.srcTenants);
    }
  }

  private mapTenantsToViewModels(tenants: Tenant[]): TenantViewModel[] {
    return _.map(tenants, t => {
      return {
        tenant: t,
        isTogglingEnabled: false

      };
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  onAddTenantUser(tenant: Tenant) {
    this.router.navigate(['Organizations', this.orgId, 'AddUser', tenant.Id, 'Users', 'Add']);
  }

  onEditTenant(tenant: Tenant) {
    this.router.navigate(['Organizations', this.orgId, 'Tenant', tenant.Id]);
  }

  /*onDeleteTenant(tenant: Tenant) {
      const dialog = this.modalService.open(SmDeleteConfDialog, { backdrop: 'static', centered: true });
      dialog.componentInstance.options = { confirmationString: tenant.TenantKey };

      dialog.result.then(
          (result) => {
              this.adminService.deleteTenant(tenant);
          },
          (error) => {
              //User dismissed the modal dialog
          }
      );
  }*/


}

export interface TenantViewModel {
  tenant: Tenant;
}
