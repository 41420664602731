import { CommonModule } from "@angular/common";
import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { BehaviorSubject, Subject } from "rxjs";

import { Area, Tenant } from "src/app/models/CodeGen/admin-model";

import { AreaService } from 'src/app/services/area-service';
import { Version } from 'src/app/services/version-service';
import { ButtonModule } from 'unity-angular';


@Component({
  selector: 'area-list',
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule
  ],
  templateUrl: './area-list.component.html',
  styleUrls: ['./area-list.component.scss']
})
export class AreaListComponent implements OnInit, OnDestroy {

  @Input() tenantId: string;
  @Input() tenantKey: string;
  @Input() orgId: string;

  public areaSource: BehaviorSubject<Area[]>;
  public updatingDBs: Set<String> = new Set<String>();
  public areaVMs: AreaViewModel[] = [];
  public areasResults: Area[] = [];
  public versionResults: Version[] = [];
  public now: number = (new Date()).getTime();
  private ngUnsubscribe: Subject<any> = new Subject();
  public tenantInfo: Tenant;

  constructor(
    private areaService: AreaService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loadAreas();
  }

  // Re-evaluate filter based on new org
  ngOnChanges() {
    this.loadAreas();
  }

  private loadAreas() {

    this.areaService.list(this.tenantKey).subscribe(value => this.areasResults = value);

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  onAddAreaUser(area: Area) {
    this.router.navigate(['Tenants', this.tenantId, 'AddUser', area.Id, 'Users', 'Add']);
  }

  onEditArea(area: Area) {
    this.router.navigate(['Tenants', this.tenantId, 'EditArea', area.Id]);
  }

  onAddArea() {

    let foundProd = false;

    this.areasResults.forEach(element => {
      if (element.AreaKey == "PROD") {
        foundProd = true;
      }
    });

    this.router.navigate(['Organizations', this.orgId, 'Tenant', this.tenantId, 'AddArea'], { queryParams: { foundProd: foundProd } });
  }

}

export interface AreaViewModel {
  area: Area;
}
