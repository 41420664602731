import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router, RouterOutlet } from "@angular/router";
import { Subject } from 'rxjs';
import { UserContext } from 'src/app/contexts/user-context';
import { Area, Tenant } from 'src/app/models/CodeGen/admin-model';
import { AreaPost, AreaService } from 'src/app/services/area-service';
import { TenantService } from 'src/app/services/tenant-service';
import { Version, VersionService } from 'src/app/services/version-service';
import { ButtonModule } from 'unity-angular';

@Component({
  selector: 'app-area',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterOutlet,
    ButtonModule
  ],
  templateUrl: './area-entry.component.html',
  styleUrls: ['./area-entry.component.scss']
})
export class AreaEntryComponent implements OnInit, OnDestroy {

  public existingAreas: Area[] = [];
  public areaKey: string;
  public areaName: string;
  public areaId: string;
  public areaNotes: string;
  public areaAlias: string;
  public areaVersionId: string;

  private ngUnsubscribe: Subject<any> = new Subject();
  private tenantId: string;
  public parentTenant: Tenant;
  private foundProd: string | null;
  public orgId: string;
  public versions: Version[] = [];
  public areaKeys: string[] = []

  constructor(private userContext: UserContext,
    private tenantService: TenantService,
    private areaService: AreaService,
    private versionService: VersionService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.orgId = params['orgId'];
      this.tenantId = params['tenantId'];
    });

    this.versionService.list(false).subscribe(value => this.versions = value);

    this.tenantService.getInfo(this.tenantId).subscribe(value => this.parentTenant = value);

    this.route.queryParamMap.subscribe(value => {
      this.foundProd = value.get('foundProd');
    });

    //TODO STG (Staging) and PROD (Production) may need to change to the full names of the area
    if (this.foundProd == "false") {
      this.areaKeys = ["DEV", "TEST", "QA", "STG", "PROD"];
    }
    else {
      this.areaKeys = ["DEV", "TEST", "QA", "STG"];
    }

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  onSubmit(f: NgForm) {

    if (!f.valid) {
      alert('Please fill out all required fields.');
      return;
    }

    let postArea = <AreaPost>{};

    postArea.areaKey = this.areaKey;
    postArea.tenantKey = this.parentTenant.TenantKey;
    postArea.name = this.areaName;
    postArea.notes = this.areaNotes;
    postArea.alias = this.areaAlias;
    postArea.versionId = Number(this.areaVersionId);

    this.areaService.create(postArea).subscribe({
      next: value => {
        console.log(value)
      },
      error: err => {
        console.error(err)
      }
    });

    return this.backToAreaList();
  };

  public backToAreaList() {
    this.router.navigate(['Organizations', this.orgId, 'Tenant', this.tenantId]);
  }
}

export interface AreaViewModel {
  area: Area;
}
