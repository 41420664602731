<div class="configure_header--right">
  <button unity-button class="primary" (click)="onAddArea()">+ Area</button>
</div>

<table>
  <tr>
    <th>Key</th>
    <th>Name</th>
    <th>Notes</th>
    <th>Alias</th>
    <th>Version</th>
  </tr>
  <tr *ngFor="let areaModel of areasResults">
    <td><a href="javascript:void(0)" (click)="onEditArea(areaModel)">{{ areaModel.AreaKey
        }}</a></td>
    <td>{{ areaModel.Name }}</td>
    <td>{{ areaModel.Notes }}</td>
    <td>{{ areaModel.Alias }}</td>
    <td>{{ areaModel.VersionNo }}</td>
  </tr>
</table>
