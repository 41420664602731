import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG } from '../app.config';

@Injectable({
  providedIn: 'root'
})
export class VersionService {

  constructor(@Inject(APP_CONFIG) private config: any, private httpClient: HttpClient) { }

  public list(includeDeprecated = true): Observable<Version[]> {
    return this.httpClient.get<Version[]>(`${this.config.ApiUrl}/Version?includeDeprecated=${includeDeprecated}`);
  }

  public getOne(versionNumber: string): Observable<Version> {
    return this.httpClient.get<Version>(`${this.config.ApiUrl}/Version/${versionNumber}`);
  }

  public create(version: Version): Observable<Version> {
    return this.httpClient.post<Version>(`${this.config.ApiUrl}/Version`, version);
  }

  public delete(versionId: number): Observable<Version> {
    return this.httpClient.delete<Version>(`${this.config.ApiUrl}/Version/${versionId}`);
  }

}

export interface Version {
  VersionId: number,
  VersionNumber: string,
  ReleaseDate: Date,
  ReleaseNote: string,
  SpaUrl: string,
  ApiUrl: string,
  Deprecated: boolean
}
