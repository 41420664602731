import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";

import { share } from "rxjs/operators";

import { BaseService } from "./base-service";
import { GlobalMessages } from "./global-messages";
import { LoadingService } from "./loading-service";
import { LoggingService } from "./logging-service";

import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router";
import { Observable, forkJoin } from "rxjs";
import { UserContext } from "../contexts/user-context";
import { Organization, Tenant } from "../models/CodeGen/admin-model";

class Response extends HttpResponse<any> { }

@Injectable()
export class AdminService extends BaseService {

    public adminLoaded: boolean;

    constructor(private http: HttpClient,
        protected override globalMessages: GlobalMessages,
        private userContext: UserContext,
        protected override loggingService: LoggingService,
        protected override loadingService: LoadingService
    ) {
        super(globalMessages, loggingService, loadingService);
    }


    private serviceUrls = class ServiceUrls {
        private static baseAdminUrl: string = BaseService.baseUrl + `/`;
        public static Organizations: string = ServiceUrls.baseAdminUrl + 'Organizations';
        public static Tenants: string = ServiceUrls.baseAdminUrl + 'Tenants';
        private static TenantUnsubstituted: string = ServiceUrls.Tenants + '/{{TenantId}}';
        public static Tenant(TenantId: string): string {
            return this.TenantUnsubstituted.replace("{{TenantId}}", TenantId);
        }

        /*public static Users: string = ServiceUrls.baseAdminUrl + 'Users';
        public static AdminUser: string = ServiceUrls.baseAdminUrl + 'AdminUser';
        public static AdminUserEnableDisable: string = ServiceUrls.baseAdminUrl + 'AdminUsers';
        public static ReloadConfig: string = ServiceUrls.baseAdminUrl + 'ReloadConfig';
        public static Environmentname: string = ServiceUrls.baseAdminUrl + 'Environment';
        public static LoggingLevels: string = ServiceUrls.baseAdminUrl + 'LoggingLevels';
        public static LoadShedding: string = ServiceUrls.baseAdminUrl + 'Env/LoadShedding';
        public static ResetDomainOperationCount: string = ServiceUrls.baseAdminUrl + 'Env/ResetDomainOperationCount';
        public static TenantFeatureFacets: string = ServiceUrls.baseAdminUrl + 'TenantFeatureFacets';

        
        public static Environment: string = ServiceUrls.baseAdminUrl + 'Env';
        public static TenantActivityEventTypes: string = ServiceUrls.baseAdminUrl + 'TenantActivityEventTypes';
        public static AreaTiers: string = ServiceUrls.Environment + '/AreaTiers';
        private static TenantsAddUnsubstituted: string = ServiceUrls.baseAdminUrl + 'Organizations/{{OrganizationId}}/Tenants';
        public static TenantsAdd(organizationId: string): string {
            return this.TenantsAddUnsubstituted.replace("{{OrganizationId}}", organizationId);
        }        
        
        public static TenantDatabaseUpdate(TenantId: string): string {
            return this.Tenant(TenantId) + '/Update';
        }
        public static TenantUsers(TenantId: string): string {
            return this.Tenant(TenantId) + '/Users';
        }

        public static TenantDisable(TenantId: string): string {
            return this.Tenant(TenantId) + '/Disable';
        }
        public static TenantEnable(TenantId: string): string {
            return this.Tenant(TenantId) + '/Enable';
        }

        private static AdminUserUnsubstituted: string = ServiceUrls.AdminUserEnableDisable + '/{{AdminUserId}}';
        public static CurrentAdminUser(AdminUserId: string): string {
            return this.AdminUserUnsubstituted.replace("{{AdminUserId}}", AdminUserId);
        }
        public static AdminUserDisable(AdminUserId: string): string {
            return this.CurrentAdminUser(AdminUserId) + '/Disable';
        }
        public static AdminUserEnable(AdminUserId: string): string {
            return this.CurrentAdminUser(AdminUserId) + '/Enable';
        }
        public static ModifyAdminUser(AdminUserId: string): string {
            return this.CurrentAdminUser(AdminUserId);
        }

        public static TenantAreas: string = ServiceUrls.baseAdminUrl + 'TenantAreas';
        private static TenantAreaUnsubstituted: string = ServiceUrls.TenantAreas + '/{{AreaId}}';
        public static TenantArea(AreaId: string): string {
            return this.TenantAreaUnsubstituted.replace("{{AreaId}}", AreaId);
        }

        private static TenantActivitiesUnsubstituted: string = ServiceUrls.baseAdminUrl + 'Organizations/{{OrganizationId}}/Activities';
        public static TenantActivities(OrganizationId: string): string {
            return this.TenantActivitiesUnsubstituted.replace("{{OrganizationId}}", OrganizationId);
        }*/
    }
    /*
        public reloadConfiguration() {
            const request = this.http.post<any>(this.serviceUrls.ReloadConfig, null).pipe(share());
    
            request.subscribe(
                response => {
                },
                error => {
                    this.handleError(error);
                }
            );
            return request;
        }*/

    public loadOrganizations() {
        const request = this.http.get<any>(this.serviceUrls.Organizations).pipe(share());

        request.subscribe(
            response => {
                this.userContext.loadApiResponseModels(Organization, response);
            },
            error => {
                this.handleError(error);
            }
        );
        return request;
    }

    public loadTenants() {
        const request = this.http.get<any>(this.serviceUrls.Tenants).pipe(share());

        request.subscribe(
            response => {
                this.userContext.loadApiResponseModels(Tenant, response);
            },
            error => {
                this.handleError(error);
            }
        );
        return request;
    }

    public addTenant = (organizationid: string, tenant: Tenant, handleErrors: boolean = true): Observable<Response> => {
        let request = this.http.post<any>(this.serviceUrls.Tenants, tenant.serialize()).pipe(share());

        var subscription = request.subscribe(
            response => {
                this.userContext.loadApiResponseModels(Tenant, response);
            },
            error => handleErrors ? this.handleError(error) : null
        );
        return request;
    }

    public editTenant(tenantId: string, tenant: Tenant, handleErrors: boolean = true): Observable<Response> {
        let request = this.http.put<any>(this.serviceUrls.Tenant(tenantId), tenant.serialize()).pipe(share());

        var subscription = request.subscribe(
            response => {
                this.userContext.loadApiResponseModels(Tenant, response);
            },
            error => handleErrors ? this.handleError(error) : null
        );
        return request;
    }

    public deleteTenant(tenant: Tenant) {

        const request = this.http.delete<any>(this.serviceUrls.Tenant(tenant.Id)).pipe(share());

        request.subscribe(() => {
            this.userContext.remove(tenant);
        });

        return request;
    }
}


export const AdminResolver: ResolveFn<UserContext> =
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        var service = inject(AdminService);
        var context = inject(UserContext);

        if (!service.adminLoaded) {
            forkJoin([
                service.loadOrganizations(),
                service.loadTenants(),
                //this.adminService.loadUsers(),
                //this.adminService.loadAreaTiers()
            ]).subscribe(() => {
                service.adminLoaded = true;
                return context;
            }, () => {
                return context;  // complete even if one request errors but allow a reload
            })
            return context;
        } else {
            return context;
        }

        //return inject(AdminService).loadOrganizations();
    };
