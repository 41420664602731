import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { APP_CONFIG } from '../app.config';
import { Tenant } from '../models/CodeGen/admin-model';

@Injectable({
  providedIn: 'root'
})
export class TenantService {

  constructor(@Inject(APP_CONFIG) private config: any, private httpClient: HttpClient) { }

  public getInfo(tenantId: string): Observable<Tenant> {
    return this.httpClient.get<Tenant>(`${this.config.ApiUrl}/Tenants/${tenantId}`);
  }

}
