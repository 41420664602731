import { HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';

import _ from 'lodash';
import { Observable, throwError } from 'rxjs';

import { AppMessage, GlobalMessages } from './global-messages';
import { LoadingService } from './loading-service';
import { LoggingService } from './logging-service';


import { DataContext } from '../contexts/data-context';

import { HttpHeaderNames } from '../enums/http-header-names';
import { IModelValidationError, ITypedServiceResponse, Model } from '../models/model';

import { environment } from 'src/environments/environment';
import { UserInteractionTypes } from '../enums/user-interaction-type.enum';

class Response extends HttpResponse<any> { }

export abstract class BaseService {

    protected static baseUrl: string = "";

    /* Number of milliseconds to keep the error message on the screen. (0 = wait to be dismissed) */
    protected readonly errorMessageTimeout: number = 0;

    constructor(
        protected globalMessages: GlobalMessages,
        protected loggingService: LoggingService,
        protected loadingService: LoadingService
    ) {
        BaseService.baseUrl = environment.baseUrl;
    }

    handleError(error: any, filterModelPaths: string[] | null = null): void {
        var err: { Errors: any; error: string | undefined; };

        try {
            if (error && error.error) {
                err = error.error;
            }
            else {
                //try {
                err = error.json();
                // } catch (exception) { }
            }

            if (err && err.Errors) {
                _.forEach(err.Errors, e => {
                    let displayError = true;
                    if (filterModelPaths) {
                        let modelError = e as IModelValidationError;
                        if (modelError && modelError.Path
                            && _.find(filterModelPaths, p => p === modelError.Path)) {
                            displayError = false;
                        }
                    }
                    if (displayError && this.globalMessages) {
                        var message = BaseService.convertCommonErrors(e);
                        this.loggingService.logException(new Error(message));
                        this.globalMessages.Add(new AppMessage((message), 'danger', this.errorMessageTimeout));
                    }
                });
            }
            else if (err && err.error && this.globalMessages) {
                this.loggingService.logException(new Error(err.error));
                this.globalMessages.Add(new AppMessage(err.error, 'danger', this.errorMessageTimeout));
            }
            else if (this.globalMessages) {
                this.loggingService.logException(error);
                this.globalMessages.Add(new AppMessage(error, 'danger', this.errorMessageTimeout));
            }
        } catch (exception) { }

        this.loadingService.loading.next(false); //  Ensure the loading flag is cleared as the api call has resolved
        this.loggingService.logInfo('An error occurred'); // for debug purposes only
    }

    public static convertCommonErrors(error: any): string {
        var message = error?.Message;

        if (message == 'Entity with value already exists' && error?.Data) {
            var data = error.Data;
            if (data.ClassName && data.PropertyName && data.PropertyValue) {
                message = data.ClassName + " with a " + data.PropertyName + " of '" + data.PropertyValue + "' already exists.";
            }
        }

        return message;
    }

    handleHttpError(error: HttpErrorResponse) {
        const self = this;
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            this.loggingService.logException(error);
            this.loggingService.logInfo('An error occurred:' + error.error.message);
        } else {
            if (error.error?.Errors) {
                _.forEach(error.error.Errors, e => {
                    if (self.globalMessages) {
                        this.loggingService.logException(new Error(e.Message));
                        self.globalMessages.Add(new AppMessage((e.Message ? e.Message : e), 'danger', this.errorMessageTimeout));
                    }
                });
            }
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    };

    public handleNormalGetRequest<T extends Model>(expectedType: new () => T,
        request: Observable<Response | HttpResponse<ITypedServiceResponse<T>> | ITypedServiceResponse<T>>,
        context: DataContext) {
        this.handleNonDeleteRequest(expectedType, request, context);
    }

    public handleNormalPostPutRequest<T extends Model>(expectedType: new () => T,
        request: Observable<Response | HttpResponse<ITypedServiceResponse<T>> | ITypedServiceResponse<T>>,
        context: DataContext) {
        this.handleNonDeleteRequest(expectedType, request, context);
    }

    private handleNonDeleteRequest<T extends Model>(expectedType: new () => T,
        request: Observable<Response | HttpResponse<ITypedServiceResponse<T>> | ITypedServiceResponse<T>>,
        context: DataContext) {
        const self = this;
        request.subscribe(
            response => {
                context.loadApiResponseModels(expectedType, response);
            },
            error => {
                self.handleError(error)
            }
        );
    }

    public handleNormalDeleteRequest<T extends Model>(expectedType: new () => T,
        request: Observable<Response | HttpResponse<ITypedServiceResponse<T>> | ITypedServiceResponse<T>>,
        item: string | Model,
        context: DataContext) {
        const self = this;
        request.subscribe(
            (response: any) => {
                context.remove(item);
            },
            error => {
                self.handleError(error)
            }
        );
    }

    public handleSoftDeleteRequest<T extends Model>(expectedType: new () => T,
        request: Observable<Response | HttpResponse<ITypedServiceResponse<T>> | ITypedServiceResponse<T>>,
        context: DataContext) {
        // If the item is expected to be soft-deleted, then don't remove it from the context. It
        // should work just like a non-delete request. 
        this.handleNonDeleteRequest(expectedType, request, context);
    }

    public getUserInteractionHeader(isInteractive: boolean): HttpHeaders {
        if (isInteractive) return new HttpHeaders().set(HttpHeaderNames.EventInteractionType, UserInteractionTypes.Interactive);
        else return new HttpHeaders().set(HttpHeaderNames.EventInteractionType, UserInteractionTypes.Noninteractive);
    }
}
