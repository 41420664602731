import { CommonModule } from "@angular/common";
import { Component, OnDestroy, OnInit } from "@angular/core";

import { Subject } from "rxjs";

import { Version, VersionService } from 'src/app/services/version-service';


@Component({
  selector: 'version-list',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './version-list.component.html',
  styleUrls: ['./version-list.component.scss']
})
export class VersionListComponent implements OnInit, OnDestroy {

  public versionResults: Version[] = [];
  public now: number = (new Date()).getTime();
  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private versionService: VersionService
  ) { }

  ngOnInit(): void {
    this.loadVersions();
  }

  // Re-evaluate filter based on new org
  ngOnChanges() {
    this.loadVersions();
  }

  public booleanFormatter(input: boolean): string {
    return (input == true) ? "Yes" : "No";
  }

  private loadVersions() {
    this.versionService.list().subscribe(value => this.versionResults = value);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  onDeleteVersion(versionNumber: number) {
    //TODO: Implement this later
    //this.versionService.delete(versionNumber).subscribe();
  }

}
