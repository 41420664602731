import { Injectable } from "@angular/core";
import { DataContext } from "./data-context";

// UserContext - Singleton (injectable).  
@Injectable({
    providedIn: 'root',
})
export class UserContext extends DataContext {
    public isLoaded: boolean = false;

    constructor() {
        super();
    }
}
