import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from "rxjs";
import { ButtonModule } from 'unity-angular';
import { VersionListComponent } from "../version-list/version-list.component";

@Component({
  selector: 'version-overview',
  standalone: true,
  imports: [
    VersionListComponent,
    ButtonModule
  ],
  templateUrl: './version-overview.component.html',
  styleUrls: ['./version-overview.component.scss']
})
export class VersionOverviewComponent implements OnInit {
  constructor(
    private router: Router) { }
    
  public orgId: string;

  ngOnInit() {

  }

  onAddVersion() {
    this.router.navigate(['Versions', 'AddVersion']);
  }
}
