<article class="container-fluid">
  <div class="configure--header">
    <h2>Tenant</h2>
  </div>
  <div class="configure--list">
    <label for="areaName"><b>Name: </b></label> {{ tenantInfo.Name }} <br>
    <label for="areaName"><b>Key: </b></label> {{ tenantInfo.TenantKey }} <br>
    <label for="areaName"><b>Notes: </b></label> {{ tenantInfo.Notes }} <br>
  </div>

  <br><br>

  <div class="configure--list">
    <h3>Areas</h3>
    <area-list [tenantId]="tenantId" [tenantKey]="tenantInfo.TenantKey" [orgId]="orgId"></area-list>
  </div>

</article>
