import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { ActivatedRoute, Router, RouterOutlet } from "@angular/router";
import { Subject, first } from 'rxjs';
import { UserContext } from 'src/app/contexts/user-context';
import { Organization, Tenant } from 'src/app/models/CodeGen/admin-model';
import { AdminService } from 'src/app/services/admin-service';
import { ButtonModule } from 'unity-angular';

@Component({
  selector: 'app-tenant',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, ButtonModule],
  templateUrl: './tenant-entry.component.html',
  styleUrls: ['./tenant-entry.component.scss']
})
export class TenantEntryComponent implements OnInit, OnDestroy {

  public existingTenant: Tenant;
  public tenantKey: string;
  public tenantName: string;
  public tenantId: string;
  public tenantNotes: string;

  private ngUnsubscribe: Subject<any> = new Subject();
  private orgId: string;
  public parentOrganization: Organization;

  private existingTenantKeys: string[] = [];

  constructor(private userContext: UserContext,
    private router: Router,
    private adminService: AdminService,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.orgId = params['orgId'];
      this.parentOrganization = <Organization>this.userContext.get(this.orgId);
      this.tenantId = params['tenantId'];
      this.cd.detectChanges();  // This is needed to load the buttons
    });

    this.userContext.getStore(new Tenant()).values.pipe(first()).subscribe(val => {
      this.existingTenantKeys.length = 0;
      this.existingTenantKeys.push(...(<Tenant[]>val).map(v => v.Id));

    });

    //TODO: Implement population if Tenant exists already
    /*
    if (this.tenantId !== null && this.tenantId !== undefined) {
        console.log(this.tenantId);

        this.userContext.getStore<Tenant>(new Tenant()).values.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
            const fromContext = _.find(data, { Id: this.tenantId });
            this.existingTenant = fromContext.clone();
        });

        this.tenantKey = this.existingTenant.Key;
        this.tenantSummary = this.existingTenant.Summary;
        this.tenantDesc = this.existingTenant.Description;
    }*/
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  onSubmit(f: NgForm) {

    if (!f.valid) {
      alert('Please fill out all required fields.');
      return;
    }

    let tenantModel = new Tenant();

    if (!this.existingTenant && this.existingTenantKeys.find(x => x === this.tenantKey)) {
      alert('Organization Name must be unique.');
      return null;
    }

    tenantModel.TenantKey = this.tenantKey;
    tenantModel.OrganizationId = this.parentOrganization.OrganizationId;
    tenantModel.Name = this.tenantName;
    tenantModel.Notes = this.tenantNotes;

    this.adminService.addTenant(this.orgId, tenantModel);

    return this.backToTenantList();
  };

  public backToTenantList() {
    this.router.navigate(['Organizations', this.orgId]);
  }
}
