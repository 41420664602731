import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { Tenant } from 'src/app/models/CodeGen/admin-model';
import { TenantService } from 'src/app/services/tenant-service';
import { AreaListComponent } from '../../area/area-list/area-list.component';
import { VersionListComponent } from '../../version/version-list/version-list.component';
import { TenantsListComponent } from "../tenant-list/tenant-list.component";

@Component({
  selector: 'tenant-page',
  standalone: true,
  imports: [
    TenantsListComponent,
    AreaListComponent,
    VersionListComponent
  ],
  templateUrl: './tenant-page.component.html',
  styleUrls: ['./tenant-page.component.scss']
})
export class TenantPageComponent implements OnInit {
  constructor(
    private service: TenantService,
    private route: ActivatedRoute) { }

  public tenantId: string;
  public orgId: string;
  public tenantInfo: Tenant;

  ngOnInit() {

    this.route.params.subscribe(params => {
      this.orgId = params['orgId'];
      this.tenantId = params['tenantId'];
    });

    this.service.getInfo(this.tenantId).subscribe(value => this.tenantInfo = value);
  }

}
