<article class="container-fluid">
  <div class="configure--header">
    <h2>Versions</h2>
    <div class="configure_header--right">
      <button unity-button class="primary" (click)="onAddVersion()">+ Version</button>
    </div>
  </div>

  <div class="configure--list">
    <version-list></version-list>
  </div>
</article>
