<form #f="ngForm" (ngSubmit)="onSubmit(f)" class="container-fluid">
  <h1 class="configure--header">Add Tenant for Organization: {{parentOrganization.Name}} </h1>

  <div class="admin--form">
    <div class="form-group">
      <label for="tenantKey">Tenant Key
        <span class="required text-danger"> *</span>
      </label>
      <input required [(ngModel)]="tenantKey" class="form-control" name="tenantKey" id="tenantKey" type="text"
        [disabled]="existingTenant != null" />
    </div>

    <div class="form-group">
      <label for="tenantName">Name
        <span class="required text-danger"> *</span>
      </label>
      <input required [(ngModel)]="tenantName" class="form-control" name="tenantName" id="tenantName" type="text" />
    </div>
    <div class="form-group">
      <label for="tenantNotes">Notes</label>
      <input [(ngModel)]="tenantNotes" class="form-control" name="tenantNotes" id="tenantNotes" type="text" />
    </div>

  </div>

  <div class="admin--footer">
    <button unity-button type="submit" class="primary">
      Create Tenant
    </button>
    <button unity-button (click)="backToTenantList()" class="tertiary">Cancel</button>
  </div>
</form>
