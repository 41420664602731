<form #f="ngForm" (ngSubmit)="onSubmit(f)" class="container-fluid">

  <h1 class="configure--header">Add Area for Tenant: {{parentTenant.Name}} </h1>

  <div class="admin--form">

    <table>
      <tr>
        <td>
          <label for="areaKey">Area Key
            <span class="required text-danger"> * </span>
          </label>
        </td>
        <td>
          <select required [(ngModel)]="areaKey" name="areaKey" class="form-control" id="areaKey" type="text">
            <option *ngFor="let key of areaKeys" value="{{key}}">
              {{key}}</option>
          </select>
        </td>
      </tr>
      <tr>
        <td>
          <label for="areaName">Name
            <span class="required text-danger"> * </span>
          </label>
        </td>
        <td>
          <input required [(ngModel)]="areaName" name="areaName" class="form-control" id="areaName" type="text"
            maxlength="10" />
        </td>
      </tr>
      <tr>
        <td>
          <label for="areaAlias">Alias
            <span class="required text-danger"> * </span>
          </label>
        </td>
        <td>
          <input required [(ngModel)]="areaAlias" name="areaAlias" class="form-control" id="areaAlias" type="text" />
        </td>
      </tr>
      <tr>
        <td>
          <label for="areaVersionId">Version
            <span class="required text-danger"> * </span>
          </label>
        </td>
        <td>
          <select required [(ngModel)]="areaVersionId" name="areaVersionId" class="form-control" id="areaVersionId"
            type="text">
            <option *ngFor="let version of versions" value="{{version.VersionId}}">
              {{version.VersionNumber}}</option>
          </select>
        </td>
      </tr>
      <tr>
        <td>
          <label for="areaNotes">Notes
            <span class="required text-danger"> * </span>
          </label>
        </td>
        <td>
          <input required [(ngModel)]="areaNotes" name="areaNotes" class="form-control" id="areaNotes" type="text" />
        </td>
      </tr>
    </table>

  </div>

  <div class="admin--footer">
    <button unity-button type="submit">
      <!-- {{ existingArea ? "Save" : "Create" }} Area -->
      Create Area
    </button>
    <button unity-button (click)="backToAreaList()" class="tertiary">Cancel</button>
  </div>
</form>
