<article>
  <div>
    <select #orgSelect (change)="orgChanged(orgSelect.value)">
      <option *ngFor="let org of organizations" [selected]="org.OrganizationKey == selectedOrg.OrganizationKey"
        value="{{ org.OrganizationKey }}">
        {{ org.Name }}
      </option>
    </select>
  </div>

  <div class="work-area">
    <article class="container-fluid">
      <div class="configure--header">
        <h2>Tenants</h2>
        <div class="configure_header--right">
          <button unity-button class="primary" (click)="onViewTenantLog()">
            Activity Log
          </button>
          <button unity-button class="primary" (click)="onAddTenant()">
            + Tenant
          </button>
        </div>
      </div>

      <div class="configure--list">
        <tenant-list [orgId]="orgId"></tenant-list>
      </div>
    </article>
  </div>
</article>
