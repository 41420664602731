import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [CommonModule],
  template: `
    <h1>404 Page Not Found</h1>
    <p>Not sure how you got here, but we couldn't find what you're looking for.</p>
  `,
  styles: []
})
export class NotFoundComponent {

}
