import * as _ from "lodash";
import { Model } from "../models/model";
import { DataStore } from "./data-store";

export abstract class DataContext {

    private stores: Map<string, DataStore<Model>>;  // Each Type of Model gets its own store  The Type is the key for that store
    private models: Map<string, Model>; // Key is the ID of the Model
    private loadingStores: Set<string> = new Set<string>();

    private _loading = false;
    /**
* When true, datastores do not broadcast changes until loading is set to false, at
* which time all stores broadcast changes if they had any.  The loading status is
* ignored when call the set(model, values) method since all values are set in one method.
*/
    get loading(): boolean {
        return this._loading;
    }

    set loading(value: boolean) {
        this._loading = value;
        if (value === false) {
            this.loadingStores.forEach(storeKey => {
                let store = this.stores.get(storeKey);
                store?.broadcast();
            });
            // reset the loading stores
            this.loadingStore.clear();
        }
    }

    stopLoadingWithoutBroadcast() {
        this._loading = false;
    }

    // tracks the stores that have been changed while _loading is set to true
    private loadingStore: Set<string> = new Set<string>();

    constructor() {
        this.stores = new Map<string, DataStore<Model>>();
        this.models = new Map<string, Model>();
    }

    getStore<T extends Model>(model: T): DataStore<T> {
        if (!model)
            return null as unknown as DataStore<T>;

        if (!this.stores.has(model.Type))
            this.stores.set(model.Type, new DataStore<Model>());

        return this.stores.get(model.Type) as unknown as DataStore<T>;
    }

    has(id: string): boolean {
        return this.models.has(id);
    }

    get<T extends Model>(id: string): T {
        return this.models.get(id) as T;
    }

    getModels(ids: Set<string>): Model[] {
        var arr: Model[] = [];

        ids.forEach(id => {
            if (this.has(id))
                arr.push(this.get(id));
        });

        return arr;
    }

    set<T extends Model>(model: T, values: T[]): void {
        _.forEach(values, val => this.models.set(val.Id, val));
        this.getStore(model).set(values);
    }

    add(model: Model): void {
        if (!model.Id)
            throw new Error("Model did not have an Id value");

        this.models.set(model.Id, model);
        this.getStore(model).add(model, !this.loading);
        if (this.loading)
            this.loadingStores.add(model.Type);

        // Now, update the loadingStores set with the "store key" matching the
        // type of the "model" parameter, as well as each type that is derived from,
        // and add the model into the store.

        // Gets the prototype of model to pass into getStore
        /* var parent: Model = this.getParentPrototype(model);
 
         // parent will not be null as long as model is not a VertexModel, EdgeModel, or BaseModel
         while (parent != null) {
             this.getStore(parent).add(model, !this.loading);
             if (this.loading)
                 this.loadingStores.add(parent.Type);
 
             // Gets the prototype of the inherited class of parent (see getParentPrototype for more information)
             parent = this.getParentPrototype(parent)
         }*/
    }

    // Gets the Prototype of the object that is passed in
    // IE: Passing in an Instantiated TaskFileRequirement would get you a TaskFileRequirement Prototype,
    // but passing in the TaskFileRequirement Prototype would give you a Task Prototype.
    getParentPrototype(model: Model): Model | null {
        var parent = Object.getPrototypeOf(model);
        return null;
    }

    replace(model: Model): void {
        if (!model.Id)
            throw new Error("Model did not have an Id value");

        var existing = this.models.get(model.Id) as any;
        if (!existing)
            this.add(model);

        var replaceModel: Model;
        if (_.isNil(existing.Version) || existing.Version <= model.Version) {
            // If the new model is newer (or the same) as the existing model, merge the edges and put
            // it in the store            
            this.models.set(model.Id, model);
        } else {
            // If the existing object is more recent than the new model,
            // reject the save because we need a new copy from the server
            //replaceModel = existing;
            throw new Error("Object was updated since last loaded!")
        }


    }

    addOrReplace(model: Model): void {
        if (!model.Id)
            throw new Error("Model did not have an Id value");

        var existing = this.models.get(model.Id);

        if (existing)
            this.replace(model);
        else
            this.add(model);
    }

    remove(model: Model | string): void {
        if (_.isString(model)) {
            model = this.get(model);

            if (model == null)
                return;
        }

        this.models.delete(model["Id"]);
    }

    clearStore(model: Model, dontBroadcast: boolean = false): void {
        if (!model)
            return;

        let isloading: boolean = this._loading; // are we currently in a loading state?

        if (!this.stores.has(model.Type))
            return;

        let store = this.getStore(model);

        if (!isloading)
            this.loading = true; // makes sure we are only broadcasting once all items are removed.

        while (store.values.value.length > 0) {
            let model = store.values.value[0];
            this.remove(model);
        }

        if (!isloading) {
            if (dontBroadcast) {
                this.stopLoadingWithoutBroadcast()
            } else {
                this.loading = false;
            }
        }
    }

    public deserializeSingleObject<T extends Model>(expectedType: { new(): T }, rawObj: any): T {
        const model = (new expectedType() as Model).deserialize(rawObj, this);
        return model as T;
    }

    /**
* This method may be used by service classes to deserialize models from an API response.
* Parameter "expectedType" may be any class that extends BaseModel.
*/
    public loadApiResponseModels<T extends Model>(expectedType: new () => T, response: any,
        postLoadAction?: () => void, remove?: boolean): T[] {
        if (_.isNil(response))
            return null as unknown as T[];

        var self = this;
        self.loading = true;
        var body = response._body || response.body || response;
        if (_.isString(body))
            body = JSON.parse(body);

        var responseContent = body.Content || body;
        const models: T[] = [];

        if (_.isArray(responseContent)) {
            // Deserialize each object in the array
            _.forEach(responseContent, obj => {
                if (!remove) {
                    models.push(self.deserializeSingleObject(expectedType, obj));
                } else {
                    self.remove(Model.GenerateId(new expectedType().Type, obj.Id));
                }
            });
        }
        else {
            if (!remove) {
                models.push(self.deserializeSingleObject(expectedType, responseContent));
            } else {
                self.remove(Model.GenerateId(new expectedType().Type, responseContent.Id));
            }
        }

        self.loading = false;

        if (postLoadAction)
            postLoadAction();
        return models;
    }

    // Use this version if you have models of different types in your response
    public loadResponseModels(response: any, postLoadAction?: () => void): Model[] {
        if (_.isNil(response))
            return null as unknown as Model[];

        var self = this;
        self.loading = true;
        var body = response._body || response.body || response;
        if (_.isString(body))
            body = JSON.parse(body);

        const models: Model[] = [];
        var responseContent = body.Content || body;

        if (responseContent && responseContent.length) {
            // Deserialize each object in the array
            _.forEach(responseContent, obj => {
                models.push(this.deserializeSingleObject(obj['@Type'], obj));
            });
        }

        self.loading = false;

        if (postLoadAction)
            postLoadAction();

        return models;
    }

    public clearAll(broadcast: boolean = true) {
        this.stores.forEach(d => {
            d.clear(broadcast);
        });
        this.models.clear();
    }

    public broadcastAll() {
        this.stores.forEach(d => {
            d.broadcast();
        })
    }


}
