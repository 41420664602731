import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { AuthService as Auth0Service } from '@auth0/auth0-angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public isAuthenticated$: Observable<boolean>;
  public user?: UserIdentity;

  constructor(private auth0Service: Auth0Service, @Inject(DOCUMENT) private document: Document) {
    this.isAuthenticated$ = auth0Service.isAuthenticated$;

    auth0Service.user$.subscribe(value => {
      if (!value) {
        this.user = undefined;
      } else {
        this.user = {
          id: value.sub ?? '',
          displayName: value.name ?? '',
          emailAddress: value.email ?? '',
          firstName: value.given_name ?? '',
          lastName: value.family_name ?? '',
          role: value['https://insurity.com/claims/roles'] ?? []
        }
      }
    });
  }

  public signIn(): void {
    this.auth0Service.loginWithRedirect();
  }

  public signOut(): void {
    this.auth0Service.logout({ logoutParams: { returnTo: this.document.location.origin } })
  }


}

export interface UserIdentity {
  id: string;
  displayName: string;
  emailAddress: string;
  firstName: string;
  lastName: string;
  role: string[];
}
