import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ButtonComponent } from 'unity-angular';
import { IdentityComponent } from '../identity/identity.component';

@Component({
  selector: 'app-global-header',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    IdentityComponent
  ],
  templateUrl: './global-header.component.html',
  styleUrls: ['./global-header.component.scss']
})
export class GlobalHeaderComponent {

  constructor(private authService: AuthService) { }

  @Output()
  public menuButtonClicked: EventEmitter<void> = new EventEmitter();

  public onMenuButtonClicked(): void {
    this.menuButtonClicked.emit();
  }

  public isGlobalAdminUser(): boolean {

    if (this.authService.user?.role.includes('Global Admin')) {
      return true;
    }
    else {
      return false;
    }
  }

}
