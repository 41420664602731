<aside class="unity-sidebar">
  <div class="nav-title">
    <span>Menu</span>
    <button unity-button type="button" buttonStyle="icon-only" class="close-button xlarge" icon="times"
      (click)="onCloseButtonClicked()"></button>
  </div>
  <nav class="unity-tabs vertical">
    <div class="unity-tab-row">
      <a class="unity-tab" [routerLink]="['/Organizations/Organization-1']" [routerLinkActive]="['active']">
        <unity-icon glyph="building" class="unity-icon-circle tab-icon"></unity-icon>
        <label>Organization</label>
      </a>
    </div>
    <div class="unity-tab-row">
      <a class="unity-tab" [routerLink]="['/Organizations/Organization-1/Tenant/Tenant-1']"
        [routerLinkActive]="['active']">
        <unity-icon glyph="copy" class="unity-icon-circle tab-icon"></unity-icon>
        <label>Tenant</label>
      </a>
    </div>
    <div class="unity-tab-row">
      <a class="unity-tab" [routerLink]="['/Organizations/Organization-1/Tenant/Tenant-1']"
        [routerLinkActive]="['active']">
        <unity-icon glyph="layers" class="unity-icon-circle tab-icon"></unity-icon>
        <label>Area</label>
      </a>
    </div>
    <div class="unity-tab-row">
      <a class="unity-tab" [routerLink]="['/Versions']" [routerLinkActive]="['active']">
        <unity-icon glyph="layers" class="unity-icon-circle tab-icon"></unity-icon>
        <label>Versions</label>
      </a>
    </div>
  </nav>
</aside>
