import { CommonModule } from '@angular/common';
import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { filter } from 'rxjs';
import { GlobalHeaderComponent } from './components/global-header/global-header.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { OrganizationOverviewComponent } from './components/organization/organization-overview/organization-overview.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    GlobalHeaderComponent,
    NavMenuComponent,
    OrganizationOverviewComponent
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(router: Router) {
    router.events
      .pipe(
        filter(value => (value instanceof NavigationEnd))
      ).subscribe(_ => {
        this.isNavMenuOpen = false;
      })
  }

  @ViewChild('backdrop')
  public backdropElementRef?: ElementRef;

  public isLoading: boolean = true;
  public isNavMenuOpen: boolean = false;

  public openNavMenu() {
    this.isNavMenuOpen = true;
  }

  public closeNavMenu() {
    this.isNavMenuOpen = false;
  }

  @HostListener('document:click', ['$event.target'])
  public onPageClick(targetElement: any): void {
    if (!this.backdropElementRef)
      return;

    const clickedInside = this.backdropElementRef.nativeElement.contains(targetElement);
    if (clickedInside) {
      this.isNavMenuOpen = false;
    }
  }
}
