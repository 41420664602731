import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { ButtonComponent, IconComponent } from 'unity-angular';

@Component({
  selector: 'app-nav-menu',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    RouterLink,
    RouterLinkActive,
    IconComponent
  ],
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent {
  @Output()
  public closeButtonClicked: EventEmitter<void> = new EventEmitter();

  public onCloseButtonClicked(): void {
    this.closeButtonClicked.emit();
  }

}
