import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormsModule, NgForm } from '@angular/forms';
import { Router, RouterOutlet } from "@angular/router";
import { Subject } from 'rxjs';
import { Version, VersionService } from 'src/app/services/version-service';
import { ButtonModule } from 'unity-angular';

@Component({
  selector: 'app-version',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    RouterOutlet,
    ButtonModule
  ],
  templateUrl: './version-entry.component.html',
  styleUrls: ['./version-entry.component.scss']
})
export class VersionEntryComponent implements OnInit, OnDestroy {

  public versionNumber: string;
  public versionReleaseDate: Date;
  public versionReleaseNotes: string;
  public versionSpaUrl: string;
  public versionApiUrl: string;
  public versionDeprecated: string;

  private existingVersions: Version[] = [];

  private ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private versionService: VersionService,
    private router: Router,
    private cd: ChangeDetectorRef) { }

  ngOnInit(): void {

    this.versionService.list().subscribe(value => {
      this.existingVersions = value;
      this.cd.detectChanges();  // This is needed to load the buttons
    });


  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  onSubmit(f: NgForm) {

    if (!f.valid) {
      alert('Please fill out all required fields.');
      return;
    }

    const versionNumber = this.versionNumber

    let versionExists = this.existingVersions.some(function (value) {
      return value.VersionNumber === versionNumber;
    });

    if (versionExists) {
      alert('Version number already exists. Change the version number or remove the existing version to continue.');
      return;
    }

    let version = <Version>{};

    version.VersionId = 0;  // If Id is not set to 0, the backend will set it to whatever is passed in
    version.VersionNumber = this.versionNumber;
    version.ReleaseDate = this.versionReleaseDate;
    version.ReleaseNote = this.versionReleaseNotes;
    version.SpaUrl = this.versionSpaUrl;
    version.ApiUrl = this.versionApiUrl;
    version.Deprecated = (this.versionDeprecated == "Yes");

    this.versionService.create(version).subscribe({
      next: value => {
        console.log(value)
      },
      error: err => {
        console.error(err)
      }
    });

    return this.backToVersionList();
  };

  public backToVersionList() {
    this.router.navigate(['Versions']);
  }
}
