import { Model } from "../model";

export class Organization extends Model {

  constructor() {
    super("Organization");
  }

  static get DerivedTypes() {
    const derivedTypes: { className: string, type: typeof Organization }[] = [

    ];
    return derivedTypes;
  }

  //Properties
  get OrganizationId(): BigInt {
    return this.data.OrganizationId;
  }

  set OrganizationId(value: BigInt) {
    this.data.OrganizationId = value;
    this.data.Id = this.GenerateId(this.Type, value);
  }

  get Name(): string {
    return this.data.Name;
  }

  set Name(value: string) {
    this.data.Name = value;
  }

  get OrganizationKey(): string {
    return this.data.OrganizationKey;
  }

  set OrganizationKey(value: string) {
    this.data.OrganizationKey = value;
  }

  serialize(_model?: Organization) {
    const model = _model || this;
    const ret = {
      ['@Type']: model.Type,
      OrganizationId: model.OrganizationId,
      Name: model.Name,
      OrganizationKey: model.OrganizationKey
    };
    //ModelUtils.removePropertiesSetToUndefined(ret);
    return ret;
  }
}

export class Tenant extends Model {

  constructor() {
    super("Tenant");
  }

  static get DerivedTypes() {
    const derivedTypes: { className: string, type: typeof Tenant }[] = [

    ];
    return derivedTypes;
  }

  //Properties
  get TenantId(): BigInt {
    return this.data.TenantId;
  }

  set TenantId(value: BigInt) {
    this.data.TenantId = value;
    this.data.Id = this.GenerateId(this.Type, value);
  }

  get TenantKey(): string {
    return this.data.TenantKey;
  }

  set TenantKey(value: string) {
    this.data.TenantKey = value;
  }

  get OrganizationId(): BigInt {
    return this.data.OrganizationId;
  }

  set OrganizationId(value: BigInt) {
    this.data.OrganizationId = value;
  }

  get Name(): string {
    return this.data.Name;
  }

  set Name(value: string) {
    this.data.Name = value;
  }

  get Notes(): string {
    return this.data.Notes;
  }

  set Notes(value: string) {
    this.data.Notes = value;
  }

  serialize(_model?: Tenant) {
    const model = _model || this;
    const ret = {
      ['@Type']: model.Type,
      TenantId: model.TenantId,
      TenantKey: model.TenantKey,
      OrganizationId: model.OrganizationId,
      Name: model.Name,
      Notes: model.Notes
    };
    return ret;
  }
}

export class AdminLogin extends Model {
  constructor() {
    super("AdminLogin");
  }

  static get DerivedTypes() {
    const derivedTypes: { className: string, type: typeof AdminLogin }[] = [

    ];
    return derivedTypes;
  }

  //Properties
  get LoginId(): BigInt {
    return this.data.LoginId;
  }

  set LoginId(value: BigInt) {
    this.data.LoginId = value;
  }

  get OrganizationId(): BigInt {
    return this.data.OrganizationId;
  }

  set OrganizationId(value: BigInt) {
    this.data.OrganizationId = value;
  }

  get Email(): string {
    return this.data.Email;
  }

  set Email(value: string) {
    this.data.Email = value;
  }

  get Super(): boolean {
    return this.data.Super;
  }

  set Super(value: boolean) {
    this.data.Super = value;
  }

}

export class Area extends Model {

  constructor() {
    super("Area");
  }

  static get DerivedTypes() {
    const derivedTypes: { className: string, type: typeof Area }[] = [

    ];
    return derivedTypes;
  }

  //Properties
  get AreaId(): BigInt {
    return this.data.AreaId;
  }

  set AreaId(value: BigInt) {
    this.data.AreaId = value;
    this.data.Id = this.GenerateId(this.Type, value);
  }

  get AreaKey(): string {
    return this.data.AreaKey;
  }

  set AreaKey(value: string) {
    this.data.AreaKey = value;
  }

  get TenantId(): BigInt {
    return this.data.TenantId;
  }

  set TenantId(value: BigInt) {
    this.data.TenantId = value;
  }

  get Name(): string {
    return this.data.Name;
  }

  set Name(value: string) {
    this.data.Name = value;
  }

  get Notes(): string {
    return this.data.Notes;
  }

  set Notes(value: string) {
    this.data.Notes = value;
  }

  get Alias(): string {
    return this.data.Alias;
  }

  set Alias(value: string) {
    this.data.Alias = value;
  }

  get VersionId(): BigInt {
    return this.data.VersionId;
  }

  set VersionId(value: BigInt) {
    this.data.VersionId = value;
  }

  get VersionNo(): string {
    return this.data.VersionNo;
  }

  set VersionNo(value: string) {
    this.data.VersionNo = value;
  }

  get EntryVersion(): string {
    return this.data.EntryVersion;
  }

  set EntryVersion(value: string) {
    this.data.EntryVersion = value;
  }

  serialize(_model?: Area) {
    const model = _model || this;
    const ret = {
      ['@Type']: model.Type,
      AreaId: model.AreaId,
      AreaKey: model.AreaKey,
      TenantId: model.TenantId,
      Name: model.Name,
      Notes: model.Notes,
      Alias: model.Alias,
      VersionId: model.VersionId,
      VersionNo: model.VersionNo,
      EntryVersion: model.EntryVersion
    };
    return ret;
  }
}
