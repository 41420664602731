import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { AreaEntryComponent } from './components/area/area-entry/area-entry.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { OrganizationOverviewComponent } from './components/organization/organization-overview/organization-overview.component';
import { TenantEntryComponent } from './components/tenant/tenant-entry/tenant-entry.component';
import { TenantPageComponent } from './components/tenant/tenant-page/tenant-page.component';
import { VersionEntryComponent } from './components/version/version-entry/version-entry.component';
import { VersionOverviewComponent } from './components/version/version-overview/version-overview.component';
import { AdminResolver, AdminService } from './services/admin-service';
import { GlobalMessages } from './services/global-messages';
import { LoadingService } from './services/loading-service';
import { LoggingService } from './services/logging-service';

export const routingConfiguration: ExtraOptions = {
  paramsInheritanceStrategy: 'always',
};

export const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    resolve: { userContext: AdminResolver },
    providers: [AdminService, GlobalMessages, LoggingService, LoadingService],
    children: [
      {
        path: 'Organizations/:orgId',
        component: OrganizationOverviewComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard],
      },
      {
        path: 'Organizations/:orgId/AddTenant',
        component: TenantEntryComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard],
      },
      {
        path: 'Organizations/:orgId/Tenant/:tenantId/AddArea',
        component: AreaEntryComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard],
      },
      {
        path: 'Organizations/:orgId/Tenant/:tenantId',
        component: TenantPageComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard],
      },
      {
        path: 'Versions',
        component: VersionOverviewComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard],
      },
      {
        path: 'Versions/AddVersion',
        component: VersionEntryComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard],
      },
    ],
  },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routingConfiguration)],
  exports: [RouterModule]
})
export class AdminRouting { }
