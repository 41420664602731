<form #f="ngForm" (ngSubmit)="onSubmit(f)" class="container-fluid">

  <h1 class="configure--header">Add A New Version</h1>

  <div class="warning">
    <strong>NOTE:</strong> Versions cannot be edited. If a change needs to be made, the version must be deleted and
    recreated.
  </div>

  <div class="admin--form">

    <table>
      <tr>
        <td>
          <label for="versionNumber">Version Number
            <span class="required text-danger"> * </span>
          </label>
        </td>
        <td>
          <input required [(ngModel)]="versionNumber" name="versionNumber" class="form-control" id="versionNumber"
            type="text" />
        </td>
      </tr>
      <tr>
        <td>
          <label for="versionReleaseDate">Release Date
            <span class="required text-danger"> * </span>
          </label>
        </td>
        <td>
          <input required [(ngModel)]="versionReleaseDate" name="versionReleaseDate" class="form-control"
            id="versionReleaseDate" type="date" />
        </td>
      </tr>
      <tr>
        <td>
          <label for="versionReleaseNotes">Release Notes
            <span class="required text-danger"> * </span>
          </label>
        </td>
        <td>
          <textarea required [(ngModel)]="versionReleaseNotes" name="versionReleaseNotes" class="form-control"
            id="versionReleaseNotes" type="text"></textarea>
        </td>
      </tr>
      <tr>
        <td>
          <label for="versionSpaUrl">SPA URL
            <span class="required text-danger"> * </span>
          </label>
        </td>
        <td>
          <input required [(ngModel)]="versionSpaUrl" name="versionSpaUrl" class="form-control" id="versionSpaUrl"
            type="text" />
        </td>
      </tr>
      <tr>
        <td>
          <label for="versionApiUrl">API URL
            <span class="required text-danger"> * </span>
          </label>
        </td>
        <td>
          <input required [(ngModel)]="versionApiUrl" name="versionApiUrl" class="form-control" id="versionApiUrl"
            type="text" />
        </td>
      </tr>
      <tr>
        <td>
          <label for="versionDeprecated">Deprecated
            <span class="required text-danger"> * </span>
          </label>
        </td>
        <td>
          <select required [(ngModel)]="versionDeprecated" name="versionDeprecated" class="form-control"
            id="versionDeprecated" type="text">
            <option selected="selected">No</option>
            <option>Yes</option>
          </select>
        </td>
      </tr>
    </table>

  </div>

  <div class="admin--footer">
    <button unity-button type="submit" class="primary">
      Create Version
    </button>
    <button unity-button (click)="backToVersionList()" class="tertiary">Cancel</button>
  </div>
</form>
